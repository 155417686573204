.Search {
    width:20px;
    position:absolute;
    top:13px;
    left:10px;
    cursor:pointer;
}

@media screen and (max-width:414px) {
    .Search {
        top:6px;
	    left:8px;
    }
}
.Header {
    height:400px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    background-position:center;
    background-repeat:no-repeat;
    position:relative;
    overflow:hidden;
    z-index:0;
}
.HeaderTitle {
    font-size:60px;
    color:#FFF;
    margin-top:0;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);
}
@media screen and (min-width:1920px) {
    .Header {
        height:450px;
    }
}
@media screen and (min-width:2560px) {
    .Header {
        height: 600px;
    }
    .HeaderTitle {
        font-size: 70px;
    }
}  
@media screen and (max-width:522px) {
    .HeaderTitle {
        font-size: 54px;
    }
}
@media screen and (max-width:474px) {
    .HeaderTitle {
        font-size: 50px;
    }
}
@media screen and (max-width:439px) {
    .HeaderTitle {
        font-size: 46px;
    }
}
@media screen and (max-width:414px) {
	.Header {
        height: 300px;
    }
    .HeaderTitle {
        font-size: 32px;
    }
}
@media screen and (max-width:360px) {
	.Header {
   	 	height: 250px;
    }
    .HeaderTitle {
        font-size: 30px;
    }
}

@media screen and (max-width:320px) {
	.Header {
   	 	height: 200px;
    }
    .HeaderTitle {
        font-size: 30px;
    }
}
.Toggle {
    width:36px;
    height:10px;
    border-radius:20px;
    margin:auto;
    background:#8AA4FE;
    position: relative;
    cursor:pointer;
    grid-area: 1/3 / 1/3;
    user-select:none;
}
.Toggle:before {
    content: "\B0 C";
    position: absolute;
    font-size: 14px;
    color: #ABABAB;
    left: -25px;
    top: -5px;
    font-weight: bolder;
    
}
.Toggle:after {
    content: "\B0 F";
    position: absolute;
    font-size: 14px;
    color: #ABABAB;
    right: -20px;
    top: -5px;
    font-weight: bolder;
}
.Circle {
    content:"";
    position:absolute;
    height:18px;
    width:18px;
    background:#3F67FC;
    border-radius:50%;
    top:-4px;
    left:0;
    margin:0;
}
@media screen and ( max-width:441px) {
    .Circle {
        height: 15px;
        width: 15px;
        top: -2px;
    }
    .Toggle {
        width: 30px;
        height: 10px;
    }
    .Toggle:before {
        font-size: 12px;
        left: -22px;
        top: -4px; 
    }
    .Toggle:after {
        font-size: 12px;
        right: -17px;
        top: -4px;
    }
}
@media screen and (max-width:575px) {
	.NightBG {
        width:110%;
	}
}
@media screen and (max-width:524px) {
	.NightBG {
        width:115%;
	}
}
@media screen and (max-width:499px) {
	.NightBG {
        width:130%;
	}
}
@media screen and (max-width:443px) {
	.NightBG {
        width:140%;
	}
}
@media screen and (max-width:375px) {
	.NightBG {
        width:120%;
	}
}
@media screen and (max-width: 360px) {
    .NightBG {
        width: 122%;
    }
}
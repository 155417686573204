.Cloud {
    margin:auto;
    width:95px;
    height:95px;
    grid-area:2/1 / 2/1;
}
@media screen and (max-width: 320px) {
    .Cloud {
        width: 60px;
    }
}
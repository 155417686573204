.WeatherCard {
    display: grid;
    min-width: 200px;
    border-radius: 8px;
    box-shadow: -2px -2px 20px rgba(55,55,55,0.1), 2px 2px 20px rgba(55,55,55,0.1);
    grid-template: 30% 33% 7% 30% / 25% 50% 25%;
    margin: auto;
    max-width: 520px;
    min-height: 300px;
    opacity:0;
}
.Temperature {
    font-size: 80px;
    color: #FFD302;
    font-weight: bold;
    margin: auto;
    grid-area: 2/2 / 2/3;
}
.Temperature span {
    font-size:40px;
}
.Feel,.Condition {
    color: #ABABAB;
    grid-area: 3/2 / 3/3;
    margin: 0;
    font-weight: bold;
}
.Condition {
    grid-area:3/1 / 3/1
}
.Location {
    text-transform: capitalize;
    grid-area: 1/2 / 1/3;
    margin:auto;
    font-size: 40px;
}
.ConditionIcon {
    margin: auto;
    grid-area: 2/1 / 2/1;
}
.WindSpeedIcon {
    grid-area:2/3 / 2 /3;
}
.WindSpeedText {
    grid-area: 3/3 / 3 /3;
    margin: 0;
    color: #ababab;
    font-weight: bold;
}
.WindDirectionIcon {
    grid-area: 3/1 / 3/1;
}
.WindDirectionText {
    grid-area: 3/2 / 3/2;
    margin: 0;
}
@media screen and (max-width:522px) {
    .WeatherCard {
        box-shadow:none;
    }
}
@media screen and (max-width:414px) {
    .WeatherCard {
        width:350px;
        box-shadow: none;
    }
    .Temperature {
        font-size: 60px;
    }
    .Location {
        font-size: 30px;
    }
    .WindSpeedText,.Feel,.Condition {
        font-size:13px;
    }
}
@media screen and (max-width:360px) {
    .WeatherCard {
        width: 340px;
    }
}
@media screen and (max-width:320px) {
    .WeatherCard {
        width: 300px;
    }
    .WeatherCard {
        min-height: 195px;
    }
    .Temperature {
        font-size:40px;
    }
    .Location {
        font-size:28px;
        grid-area:1/1 / 1/3;
        margin:auto;
    }
}
@media only screen 
and (min-width:768px) 
and (max-height:1024px)
and (-webkit-min-device-pixel-ratio: 2) {
    .WeatherCard {
    max-width: 620px;
    min-height: 400px;
    }
}
@media screen and (min-width:2560px) {
    .WeatherCard {
        max-width: 620px;
        min-height: 400px;
    }
    .Location {
        font-size: 50px;
    }
}   
html {
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:center;
  height:100%;
  min-height:100%;
  font-size:16px;
}
#root {
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
}
.Pellets {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Pellets {
        width: 60px;
    }
}
input {
    padding: 15px;
    border: none;
    background: #eeeeee;
    border-radius: 2px;
    font-weight:bolder;
    width:350px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    padding-left: 35px;
    font-size:16px;
  }
input:focus {
  outline:none;
}
.InputContainer {
  position:relative;
}
.Button {
  border:none;
  font-weight:bolder;
  cursor:pointer;
  background:#FFD302;
  color:#FFF;
  padding:15px 25px;
  border-radius:2px;
  margin-left:10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  font-size:16px;
  user-select:none;
}
.Button:hover {
  background: rgba(255, 211, 2,0.95);
}
.Button:focus {
  outline:none;
}
@media screen and (min-width:2560px) {
  input {
      width: 450px;
  }
}
@media screen and (max-width:522px) {
  input {
    width: 310px;
  }
}
@media screen and (max-width:474px) {
  input {
    width: 270px;
  }
}
@media screen and (max-width:439px) {
  input {
    width: 240px;
  }
}

@media screen and (max-width:414px) {
  input {
    width: 150px;
    padding:10px 0 10px 35px;
    font-size:12px;
  }
  .Button {
    padding: 10px 15px;
    font-size:12px;
  }
  
}
@media screen and (max-width:360px) {
  input {
    width: 150px;
  }
}
@media screen and (max-width:320px) {
  input {
    width: 130px;
  }
}
@media only screen 
and (min-width:768px) 
and (max-height:1024px)
and (-webkit-min-device-pixel-ratio: 2) {
   .Header {
    height:500px;
   }
}
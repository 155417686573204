html {
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:center;
  height:100%;
  min-height:100%;
  font-size:16px;
}
#root {
  height:100%;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.WeatherCard {
    display: grid;
    min-width: 200px;
    border-radius: 8px;
    box-shadow: -2px -2px 20px rgba(55,55,55,0.1), 2px 2px 20px rgba(55,55,55,0.1);
    grid-template: 30% 33% 7% 30% / 25% 50% 25%;
    margin: auto;
    max-width: 520px;
    min-height: 300px;
    opacity:0;
}
.Temperature {
    font-size: 80px;
    color: #FFD302;
    font-weight: bold;
    margin: auto;
    grid-area: 2/2 / 2/3;
}
.Temperature span {
    font-size:40px;
}
.Feel,.Condition {
    color: #ABABAB;
    grid-area: 3/2 / 3/3;
    margin: 0;
    font-weight: bold;
}
.Condition {
    grid-area:3/1 / 3/1
}
.Location {
    text-transform: capitalize;
    grid-area: 1/2 / 1/3;
    margin:auto;
    font-size: 40px;
}
.ConditionIcon {
    margin: auto;
    grid-area: 2/1 / 2/1;
}
.WindSpeedIcon {
    grid-area:2/3 / 2 /3;
}
.WindSpeedText {
    grid-area: 3/3 / 3 /3;
    margin: 0;
    color: #ababab;
    font-weight: bold;
}
.WindDirectionIcon {
    grid-area: 3/1 / 3/1;
}
.WindDirectionText {
    grid-area: 3/2 / 3/2;
    margin: 0;
}
@media screen and (max-width:522px) {
    .WeatherCard {
        box-shadow:none;
    }
}
@media screen and (max-width:414px) {
    .WeatherCard {
        width:350px;
        box-shadow: none;
    }
    .Temperature {
        font-size: 60px;
    }
    .Location {
        font-size: 30px;
    }
    .WindSpeedText,.Feel,.Condition {
        font-size:13px;
    }
}
@media screen and (max-width:360px) {
    .WeatherCard {
        width: 340px;
    }
}
@media screen and (max-width:320px) {
    .WeatherCard {
        width: 300px;
    }
    .WeatherCard {
        min-height: 195px;
    }
    .Temperature {
        font-size:40px;
    }
    .Location {
        font-size:28px;
        grid-area:1/1 / 1/3;
        margin:auto;
    }
}
@media only screen 
and (min-width:768px) 
and (max-height:1024px)
and (-webkit-min-device-pixel-ratio: 2) {
    .WeatherCard {
    max-width: 620px;
    min-height: 400px;
    }
}
@media screen and (min-width:2560px) {
    .WeatherCard {
        max-width: 620px;
        min-height: 400px;
    }
    .Location {
        font-size: 50px;
    }
}   
.Wind {
    width:95px;
}
@media screen and (max-width: 320px) {
    .Wind {
        width: 60px;
    }
}
.Sun {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Sun {
        width: 60px;
    }
}
.Cloud {
    margin:auto;
    width:95px;
    height:95px;
    grid-area:2/1 / 2/1;
}
@media screen and (max-width: 320px) {
    .Cloud {
        width: 60px;
    }
}
.PartlyCloudy {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PartlyCloudy {
        width: 60px;
    }
}
.PartlyCloudy {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PartlyCloudy {
        width: 60px;
    }
}
.Clear {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Clear {
        width: 60px;
    }
}
.Cloudy{
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Cloudy {
        width: 60px;
    }
}
.Mist {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Mist {
        width: 60px;
    }
}
.PatchyRain {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PatchyRain {
        width: 60px;
    }
}
.PatchyRain {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PatchyRain {
        width: 60px;
    }
}
.PatchySnow {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PatchySnow {
        width: 60px;
    }
}
.PatchySleet {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .PatchySleet {
        width: 60px;
    }
}
.Lightning {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Lightning {
        width: 60px;
    }
}
.BlowingSnow {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .BlowingSnow {
        width: 60px;
    }
}
.FreezingFog {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .FreezingFog {
        width: 60px;
    }
}
.Rain {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Rain {
        width: 60px;
    }
}
.FreezingRain {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .FreezingRain {
        width: 60px;
    }
}
.Pellets {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .Pellets {
        width: 60px;
    }
}
.RainThunder {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .RainThunder {
        width: 60px;
    }
}
.SnowThunder {
    grid-area: 2/1 / 2/1;
    margin: auto;
    width: 95px;
    height: 95px;
}
@media screen and (max-width: 320px) {
    .SnowThunder {
        width: 60px;
    }
}
.Header {
    height:400px;
    width:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-position:center;
    background-repeat:no-repeat;
    position:relative;
    overflow:hidden;
    z-index:0;
}
.HeaderTitle {
    font-size:60px;
    color:#FFF;
    margin-top:0;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);
}
@media screen and (min-width:1920px) {
    .Header {
        height:450px;
    }
}
@media screen and (min-width:2560px) {
    .Header {
        height: 600px;
    }
    .HeaderTitle {
        font-size: 70px;
    }
}  
@media screen and (max-width:522px) {
    .HeaderTitle {
        font-size: 54px;
    }
}
@media screen and (max-width:474px) {
    .HeaderTitle {
        font-size: 50px;
    }
}
@media screen and (max-width:439px) {
    .HeaderTitle {
        font-size: 46px;
    }
}
@media screen and (max-width:414px) {
	.Header {
        height: 300px;
    }
    .HeaderTitle {
        font-size: 32px;
    }
}
@media screen and (max-width:360px) {
	.Header {
   	 	height: 250px;
    }
    .HeaderTitle {
        font-size: 30px;
    }
}

@media screen and (max-width:320px) {
	.Header {
   	 	height: 200px;
    }
    .HeaderTitle {
        font-size: 30px;
    }
}
.Main {
    /* padding-top:20px; */
    /* margin:auto; */
}
.Toggle {
    width:36px;
    height:10px;
    border-radius:20px;
    margin:auto;
    background:#8AA4FE;
    position: relative;
    cursor:pointer;
    grid-area: 1/3 / 1/3;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
}
.Toggle:before {
    content: "\B0   C";
    position: absolute;
    font-size: 14px;
    color: #ABABAB;
    left: -25px;
    top: -5px;
    font-weight: bolder;
    
}
.Toggle:after {
    content: "\B0   F";
    position: absolute;
    font-size: 14px;
    color: #ABABAB;
    right: -20px;
    top: -5px;
    font-weight: bolder;
}
.Circle {
    content:"";
    position:absolute;
    height:18px;
    width:18px;
    background:#3F67FC;
    border-radius:50%;
    top:-4px;
    left:0;
    margin:0;
}
@media screen and ( max-width:441px) {
    .Circle {
        height: 15px;
        width: 15px;
        top: -2px;
    }
    .Toggle {
        width: 30px;
        height: 10px;
    }
    .Toggle:before {
        font-size: 12px;
        left: -22px;
        top: -4px; 
    }
    .Toggle:after {
        font-size: 12px;
        right: -17px;
        top: -4px;
    }
}
.Footer {
    color:#ABABAB;
    margin-bottom: 15px;
}
.footerLink {
    text-decoration: none;
    color: #3F67FC;
    font-weight:bolder;
}
.Heart {
    width:13px;
}
input {
    padding: 15px;
    border: none;
    background: #eeeeee;
    border-radius: 2px;
    font-weight:bolder;
    width:350px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    padding-left: 35px;
    font-size:16px;
  }
input:focus {
  outline:none;
}
.InputContainer {
  position:relative;
}
.Button {
  border:none;
  font-weight:bolder;
  cursor:pointer;
  background:#FFD302;
  color:#FFF;
  padding:15px 25px;
  border-radius:2px;
  margin-left:10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  font-size:16px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.Button:hover {
  background: rgba(255, 211, 2,0.95);
}
.Button:focus {
  outline:none;
}
@media screen and (min-width:2560px) {
  input {
      width: 450px;
  }
}
@media screen and (max-width:522px) {
  input {
    width: 310px;
  }
}
@media screen and (max-width:474px) {
  input {
    width: 270px;
  }
}
@media screen and (max-width:439px) {
  input {
    width: 240px;
  }
}

@media screen and (max-width:414px) {
  input {
    width: 150px;
    padding:10px 0 10px 35px;
    font-size:12px;
  }
  .Button {
    padding: 10px 15px;
    font-size:12px;
  }
  
}
@media screen and (max-width:360px) {
  input {
    width: 150px;
  }
}
@media screen and (max-width:320px) {
  input {
    width: 130px;
  }
}
@media only screen 
and (min-width:768px) 
and (max-height:1024px)
and (-webkit-min-device-pixel-ratio: 2) {
   .Header {
    height:500px;
   }
}
.Search {
    width:20px;
    position:absolute;
    top:13px;
    left:10px;
    cursor:pointer;
}

@media screen and (max-width:414px) {
    .Search {
        top:6px;
	    left:8px;
    }
}
.Thermometer {
    height:100px;
    width:100px;
    opacity:0;
}
.DayBG {
    position: absolute;
    left: 0;
    width:100%;
    z-index: -1;
}
.NightBG {
    position: absolute;
    left: 0;
    width:100%;
    z-index: -1;
}

@media screen and (max-width:575px) {
	.DayBG {
        width:110%;
	}
}
@media screen and (max-width:524px) {
	.DayBG {
        width:115%;
	}
}
@media screen and (max-width:499px) {
	.DayBG {
        width:130%;
	}
}
@media screen and (max-width:443px) {
	.DayBG {
        width:140%;
	}
}
@media screen and (max-width:375px) {
	.DayBG {
        width:120%;
	}
}
@media screen and (max-width: 360px) {
    .DayBG {
        width: 122%;
    }
}
@media screen and (max-width:575px) {
	.NightBG {
        width:110%;
	}
}
@media screen and (max-width:524px) {
	.NightBG {
        width:115%;
	}
}
@media screen and (max-width:499px) {
	.NightBG {
        width:130%;
	}
}
@media screen and (max-width:443px) {
	.NightBG {
        width:140%;
	}
}
@media screen and (max-width:375px) {
	.NightBG {
        width:120%;
	}
}
@media screen and (max-width: 360px) {
    .NightBG {
        width: 122%;
    }
}
